<template>
  <div>
    <div class="dashboard-container" :style="noGutter ? 'margin: 0;' : ''">
      <div class="page-card" style="padding: unset;">
        <div style="display: flex; justify-content: space-between;align-items: start;">
          <div class="page-card-header" v-if="showHeader">
            <div class="card-operation">
              <slot name="page-actions"></slot>
            </div>
          </div>
          <div class="flex justifyBetween" v-if="showSearch">
            <page-searcher :searchMethod="() => loadData(true)" :searchModel="searchModel">
              <template slot="search-items">
                <slot name="search-items"></slot>
              </template>
            </page-searcher>
          </div>
        </div>
        <slot name="deUserCount"></slot>
        <div class="page-card-boody" :style="noGutter ? 'padding: 20px 0;' : ''">
          <el-table
                  :data="listData"
                  @sort-change="handleSortChange"
                  v-loading="listLoading"
                  :summary-method="getSummaries"
                  :show-summary="showSummary"
                  border
                  @selection-change="handleSelectionChange"
                  highlight-current-row
                  @current-change="handleTableCurrentChange"
                  ref="table"
                  style="width: 100%"
                  class="table-component"
                  tooltip-effect="dark">
            <el-table-column type="selection" width="55" align="center" v-if="showSelection"></el-table-column>
            <el-table-column label="序号" align="center" type="index" v-if="showSerialNo">
            </el-table-column>
            <el-table-column v-for="(field, index) in fields" :key="index" :label="field.label" :prop="field.key"
                             :width="field.width || ''"
                             :fixed="field.fixed ? field.fixed : false"
                             :sortable="field.sortable ? 'custom' : false"
                             :align="field.align ? field.align : 'center'">
              <template slot-scope="scope">
                <column-content :row="scope.row" :field="field"></column-content>
              </template>
            </el-table-column>
            <el-table-column label="操作" v-if="itemActions.length > 0" align="center"
                             fixed="right" :width="Math.max(itemActions.length * 60, 100)">
              <template slot-scope="scope">
                <column-actions :row="scope.row" :actions="itemActions"
                                @aclick="(action, row) => callAction(action, row)"></column-actions>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
                  v-if="showPaginate"
                  class="page-card-pagination"
                  style="text-align: center"
                  :current-page="page.current"
                  :page-size="page.size"
                  :page-sizes="[10, 20, 30, 50, 100]"
                  :total="page.total"
                  @current-change="handleCurrentChange"
                  @size-change="handleSizeChange"
                  layout="total, sizes, prev, pager, next, jumper">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSearcher from "@/components/PageSearcher"
import {getTableSummaries} from "@/function/element.helper"
// import {Dropdown, DropdownMenu, DropdownItem} from 'element-ui'
import checkPermission from "@/utils/checkPermission"

export default {
  name: "PageTable",
  props: {
    showSearch: {
      type: Boolean,
      default() {
        return true
      }
    },
    searchModel: {
      type: Object,
      default() {
        return {}
      }
    },
    showPaginate: {
      type: Boolean,
      default() {
        return false
      }
    },
    getData: {
      type: Function,
      required: true,
    },
    getDataParams: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    fields: {
      type: Array,
      required: true
    },
    itemActions: {
      type: Array,
      default() {
        return []
      }
    },
    showSummary: {
      type: Boolean,
      default() {
        return false
      }
    },
    showSummaryColumns: {
      type: Array,
      default() {
        return []
      }
    },
    showSelection: {
      type: Boolean,
      default() {
        return false
      }
    },
    showSerialNo: {
      type: Boolean,
      default() {
        return false
      }
    },
    autoLoad: {
      type: Boolean,
      default() {
        return true
      }
    },
    showHeader: {
      type: Boolean,
      default() {
        return true
      }
    },
    noGutter: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  data() {
    return {
      searchItemCount: 0,
      showMoreSearch: false,
      listLoading: false,
      listData: [],
      page: {
        current: 1,
        size: 20,
        total: 0,
      },
      order_by_column: 'id',
      order_by_direction: 'desc',
      selection: [],
      loadDataLock: false,
      currentRow: null,
    }
  },
  components: {
    PageSearcher,
    ColumnContent: {
      props: {
        row: {
          required: true
        },
        field: {
          required: true
        }
      },
      render(h) {
        if (this.field.render) {
          return this.field.render(this.row, h)
        }
        if (this.field.key) {
          return h('div', {
            attrs: {
              class: this.field.space ? this.field.space : 'nowrap'
            }
          }, this.row[this.field.key])
        }
      }
    },
    ColumnActions: {
      props: {
        row: {
          required: true
        },
        actions: {
          required: true
        },
      },
      render(h) {
        let onClick = (action, row) => {
          this.$emit('aclick', action, row)
        }
        let showActions = this.actions.filter((action) => {
          if (action.permission !== undefined && !checkPermission(action.permission)) return false
          return (!action.showAction || action.showAction(this.row))
        })
        let actions = []
        for (let i in showActions) {
          actions.push(h('div', {
            attrs: {
              class: 'table-action-item'
            },
            on: {
              click: () => {
                onClick(showActions[i].action, this.row)
              }
            }
          }, showActions[i].label))
        }
        return h('div', {
          attrs: {
            class: 'table-actions'
          }
        }, actions)
      }
    },
  },
  created() {

  },
  mounted() {
    if (this.autoLoad) {
      this.loadData()
    }
  },
  methods: {
    loadData(reset_page = false) {
      let that = this
      if (reset_page) {
        that.page.current = 1
      }
      if (that.loadDataLock) return false
      that.loadDataLock = true
      let query = {
        order_by_column: that.order_by_column,
        order_by_direction: that.order_by_direction,
      }
      if (that.showPaginate) {
        query.page = that.page.current
        query.limit = that.page.size
      }
      Object.assign(query, that.searchModel)
      that.listLoading = true
      that.getData(...that.getDataParams, query).then(res => {
        if (that.showPaginate) {
          that.page.total = parseInt(res.total)
          that.page.current = parseInt(res.current)
          that.listData = res.records
          if (res.otherObject != null) {
            res.otherObject.total = res.total;
            that.$parent.otherObject = res.otherObject
          }
        } else {
          that.listData = res
        }
        that.listLoading = false
        that.loadDataLock = false
      }).catch(() => {
        that.listLoading = false
        that.loadDataLock = false
      })
    },
    handleSortChange(column) {
      this.order_by_column = column.prop
      this.order_by_direction = column.order == 'ascending' ? 'asc' : 'desc'
      this.loadData()
    },
    handleSelectionChange(val) {
      this.selection = val
    },
    handleTableCurrentChange(val) {
      this.currentRow = val
      this.$emit('onCurrentChange', val)
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.loadData()
    },
    handleSizeChange(val) {
      this.page.size = val
      this.loadData()
    },
    callAction(action, item) {
      this.$emit('onActionClick', action, item)
    },
    getSummaries({columns, data}) {
      return getTableSummaries(this.showSummaryColumns, columns, data)
    },
  },
}
</script>

<style scoped>
.page-card {
  background-color: transparent !important;
}

.page-card .page-card-header {
  text-align: left;
  padding: 5px 0 !important;
  border-bottom: 0 solid #ebeef5;
}

.page-card-boody {
  background-color: white !important;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1600);
  border-radius: 7px;
}

.el-table{
  color: #000;
}
.el-icon-refresh {
  color: red !important
}

.el-table--group, .el-table--border {
  border: unset !important;
}

.el-table__header thead th {
  border-top: 1px solid #141414 !important;
  border-bottom: 1px solid #141414 !important;
}

/deep/ .el-table th.el-table__cell > .cell {
  padding-left: 3px;
  padding-right: 3px;
  color: #000;
  font-weight: normal;
}


/deep/ .el-table--border .el-table__cell {
  border-right: unset !important;
}
</style>
