<template>
  <div class="page-seacher">
    <el-form :inline="true" :model="searchModel" @submit.native.prevent class="page-searcher-form"
             ref="pageSearchRef" size="mini">
      <div :class="showMoreSearch ? 'show-more' : 'hide-more'"
           class="page-searcher-items" ref="searchItemsRef">
        <slot name="search-items"></slot>
        <el-form-item class="search-actions">
          <el-button @click="searchFormReset('pageSearchRef')" plain type="primary">重置</el-button>
          <el-button @click="searchFormSubmit" type="primary">查找</el-button>
          <!--<el-button @click="switchSearchStatus" type="text" v-if="searchItemCount > 4">-->
            <!--{{ showMoreSearch ? '收起' : '展开' }} <i :class="showMoreSearch ? 'hide-more-icon' : 'show-more-icon'"-->
                                                  <!--class="el-icon-arrow-down"></i>-->
          <!--</el-button>-->
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "PageSearcher",
  props: {
    searchModel: {
      type: Object,
      default() {
        return {}
      }
    },
    searchMethod: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      searchItemCount: 0,
      showMoreSearch: false,
    }
  },
  created() {

  },
  mounted() {
    let that = this
    that.searchItemCount = that.$refs.searchItemsRef.children.length - 1
  },
  methods: {
    searchFormSubmit() {
      if (this.searchMethod) {
        this.searchMethod()
      }
    },
    searchFormReset(formName) {
      this.$refs[formName].resetFields()
      this.searchMethod()
    },
    switchSearchStatus() {
      this.showMoreSearch = !this.showMoreSearch
    }
  },
}
</script>

<style lang="scss" scoped>
.page-seacher {

  .page-searcher-form {
    min-height: 36px;

    .page-searcher-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-right: 10px;

      & /deep/ .el-form-item__label{
        padding: 0 10px 0 0;
      }

      & /deep/ .el-form-item {
        transition: opacity .2s linear;
        display: block;
        padding-left: 0;


        .el-form-item__content {
          /*width: calc(100% - 80px);*/
          width: 130px;

          .el-select,
          .el-cascader,
          .el-date-editor,
          .el-range-editor,
          .el-input__inner {
            width: 100%;
          }
        }
      }

      .search-actions {
        flex: 1;
        display: flex !important;
        justify-content: flex-end;
        max-width: unset;

        & /deep/ .el-form-item__content {
          display: flex;
          justify-content: flex-end;
          margin-left: 10px;

          .el-icon-arrow-down {
            margin-left: .5em;
            transition: all .3s ease 0s;
          }

          .show-more-icon {
            transform: rotate(0turn);
          }

          .hide-more-icon {
            transform: rotate(.5turn);
          }
        }
      }
    }
  }
}
</style>
